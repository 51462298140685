import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Heading from "../components/Heading";
import Hero from "../components/Hero";
import Paragraph from "../components/Paragraph";
import CallToAction from "../components/CallToAction";
import ResponsiveContainer from "../components/ResponsiveContainer";

const NotFoundPage = () => {
  const hero = (
    <Hero
      title="Sorry, we can't find what you're looking for."
      showFindOutMore={false}
    />
  );
  return (
    <Layout hero={hero} showContactForm={false}>
      <SEO title="404: Not found" />
      <ResponsiveContainer width="thin">
        <Heading align="center" spacingTop="none">
          404: Page Not Found
        </Heading>
        <Paragraph align="center">
          Uh oh, looks like this page cannot be found.
          Please head back to home and try again.
        </Paragraph>
        <CallToAction caption="Back to home" align="center" link="/" />
      </ResponsiveContainer>
    </Layout>
  );
}

export default NotFoundPage;
